:root {
  --bs-blue: #004F9F;
}

.btn-primary {
  color: #fff;
  background-color: var(--bs-blue);
  border-color: var(--bs-blue);
}

.nav-link {
  color: var(--bs-blue);
}

body,
html,
#root {
  margin: 0;
  padding: 0;
  font-family: "Roboto";
  background-color: #F3F5FB;
}

.ck-editor__editable {
  min-height: 240px;
}